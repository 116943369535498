<template>
  <div>
    <el-card shadow="never">
      <div class="d-flex">
        <!-- <div>
          <el-input
            class="w220 mr10"
            v-model="searchTitle"
            placeholder="请输入标题查询"
          />
          <el-button type="primary">查询</el-button>
        </div> -->
        <div style="margin-left: auto">
          <el-button type="primary" @click="addType"
            >添加分类</el-button
          >
        </div>
      </div>
      <el-table
        class="mt-16"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="sortId"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="sortId" label="编号" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="sortName"
          label="分类名称"
          align="center"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" width="200px">
          <div slot-scope="scope">
            <div v-if="scope.row.oneId == 0">
              <el-button type="text" @click="editSort(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除该分类以及该分类包含的子项？"
                @confirm="deleteType(scope.row)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
              <el-button class="ml-10" type="text" @click="addCldItem(scope.row)"
                >添加子分类</el-button
              >
            </div>
            <div v-else>
              <el-button type="text" @click="editCldItem(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除该分类？"
                @confirm="deleteType(scope.row)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form :model="ruleForm" ref="ruleForm" label-width="78px">
        <el-form-item label="分类名称" prop="title">
          <el-input
            v-model="ruleForm.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="title">
          <el-input
            v-model="ruleForm.rank"
            placeholder="数值越大，排序越前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subMit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible1"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form :model="ruleForm1" ref="ruleForm" label-width="78px">
        <el-form-item label="分类名称" prop="title">
          <el-input
            v-model="ruleForm1.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="title">
          <el-input
            v-model="ruleForm1.rank"
            placeholder="数值越大，排序越前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="subMit1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProduceSort,
  saveProduceSort,
  deleteProduceSort,
} from "@/api/searchMange";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      ruleForm: {},
      ruleForm1: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      companyId: "",
      userId: "",
      searchTitle: "",
      fatherSortId: "",
    };
  },
  created() {
    let info = JSON.parse(localStorage.getItem("info"));
    this.companyId = info.companyId;
    this.userId = info.userId;
    this.inits();
  },
  methods: {
    deleteType(item){
      deleteProduceSort({sortId:item.sortId}).then(res=>{
        if(res.code==200){
          this.$message.success('删除成功！');
          this.inits();
        }
      })
    },
    addType(){
      this.ruleForm={};
      this.dialogVisible=true;
      this.ruleForm.companyId=this.companyId;
    },
    inits() {
      let prams = {
        companyId: this.companyId,
      };
      console.log(prams, "请求分类");
      getProduceSort(prams).then((res) => {
        this.tableData = res.data.list;
      });
    },
    editSort(item){
      this.ruleForm=JSON.parse(JSON.stringify(item));
      this.dialogVisible = true;
    },
    subMit() {
      this.ruleForm.oneId = 0;
      console.log(this.ruleForm, "添加分类");
      saveProduceSort(this.ruleForm).then((res) => {
        if(res.code==200){
          this.$message.success('保存成功！');
          this.inits();
          this.dialogVisible=false;
        }
      });
    },
    addCldItem(item) {
      // this.ruleForm1.sortId = item.sortId;
      this.ruleForm1={};
      this.ruleForm1.oneId = item.sortId;
      this.ruleForm1.companyId=this.companyId;
      this.dialogVisible1 = true;
    },
    editCldItem(item){
      this.ruleForm1=JSON.parse(JSON.stringify(item));
      this.dialogVisible1 = true;
    },
    subMit1() {
      console.log(this.ruleForm1, "添加分类");
      saveProduceSort(this.ruleForm1).then((res) => {
        if(res.code==200){
          this.$message.success('保存成功！');
          this.inits();
          this.dialogVisible1=false;
        }
      });
    },
  },
};
</script>

<style>
</style>