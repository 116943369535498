<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="搜索列表" name="first">
        <tabs1 v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="搜索分类" name="second">
        <tabs2 v-if="activeName == 'second'" :drafts="true" />
      </el-tab-pane>
      <el-tab-pane label="产品分类" name="three">
        <tabs3 v-if="activeName == 'three'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  
  <script>
import tabs1 from "./components/tab1.vue";
import tabs2 from "./components/tab2.vue";
import tabs3 from "./components/tab3.vue";
export default {
  name: "spike",
  components: {
    tabs1,
    tabs2,
    tabs3,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>