import httpUtil from "@/utils/httpUtil";

/** 获取产品列表 */
export const getProduce = async params => httpUtil.post("/api/crmApi/produce/getProduce ", params)

/** 保存产品列表 */
export const saveProduce = async params => httpUtil.post("/api/crmApi/produce/saveProduce ", params)

/** 删除产品列表 */
export const deleteProduce = async params => httpUtil.post("/api/crmApi/produce/deleteProduce ", params)

/** 获取分类列表 */
export const getProduceSort = async params => httpUtil.post("/api/crmApi/produce/getProduceSort ", params)

/** 保存分类 */
export const saveProduceSort = async params => httpUtil.post("/api/crmApi/produce/saveProduceSort ", params)

/** 删除分类 */
export const deleteProduceSort = async params => httpUtil.post("/api/crmApi/produce/deleteProduceSort ", params)

/** 获取分类列表 */
export const getProduceClass = async params => httpUtil.post("/api/crmApi/produce/getProduceClass ", params)

/** 保存分类 */
export const saveProduceClass = async params => httpUtil.post("/api/crmApi/produce/saveProduceClass ", params)

/** 删除分类 */
export const deleteProduceClass = async params => httpUtil.post("/api/crmApi/produce/deleteProduceClass ", params)