<template>
  <div
    class="box"
    v-loading="daoruType"
    element-loading-text="导入中，请稍后"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)"
    style="min-height:80vh;"
  >
    <el-card shadow="never">
      <div class="d-flex">
        <!-- <div>
          <el-input
            class="w220 mr10"
            v-model="searchTitle"
            placeholder="请输入标题查询"
          />
          <el-button type="primary">查询</el-button>
        </div> -->
        <div class="flex" style="margin-left: auto">
          <div class="uploadExcel">
            <el-upload
              class="upload-demo"
              action="https://card.api.jquen.com/crmApi/produce/importExcel"
              :on-success="uploadAddProduct"
              :on-progress="handProgress1"
              :show-file-list="false"
              :data="uploadData"
            >
              <el-button type="primary">导入产品</el-button>
            </el-upload>
          </div>

          <el-button class="mb-5 ml-16" type="primary" @click="addSearchItem"
            >添加产品</el-button
          >
        </div>
      </div>

      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column type="index" align="center" label="序号" />
          <el-table-column prop="produceId" align="center" label="产品编号" />
          <el-table-column prop="name" align="center" label="标题" />
          <el-table-column prop="cover" align="center" label="封面">
            <div slot-scope="scope">
              <img class="w50 h50" :src="scope.row.cover" alt="" />
            </div>
          </el-table-column>
          <!-- <el-table-column prop="userName" align="center" label="排序" /> -->
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="editItem(scope.row)"
                >设置</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除产品？"
                @confirm="delSearchItem(scope.row)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加产品"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form :model="ruleForm" ref="ruleForm" label-width="78px">
        <el-form-item label="产品名称">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="别家型号">
          <el-input
            v-model="ruleForm.otherName"
            placeholder="请输入别家型号"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择大类">
          <div class="flex">
            <el-select
              v-model="ruleForm.classify"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in classifyList"
                :key="index"
                :label="item.className"
                :value="item.classId"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="搜索分类">
          <el-cascader
            :options="typeList"
            :props="props"
            collapse-tags
            clearable
            :show-all-levels="false"
            v-model="ruleForm.produceSort"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="封面">
          <el-upload
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            class="avatar-coverImg"
            :class="{ disabled: uploadDisabled }"
            :limit="1"
            :on-progress="handProgress"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="h100p d-flex align-items-center">
            <p>建议上传750x600，格式可为png、jpg或jpeg</p>
          </div>
        </el-form-item>
        <el-form-item label="产品内容">
          <quill-editor
            style="height: 100%"
            v-model="ruleForm.content"
            ref="myQuillEditor"
            :options="editorOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subMit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  getProduce,
  saveProduce,
  deleteProduce,
  getProduceSort,
  getProduceClass,
} from "@/api/searchMange";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

import { Video } from "@/utils/video";
// const quill = require('quill');
Quill.register({ "formats/video": Video }, true);

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请输入";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      companyId: "",
      userId: "",
      searchTitle: "",
      dialogVisible: false,
      ruleForm: {},
      fileList: [],
      editorOption: quillConfig,
      typeList: [],
      props: { multiple: true, emitPath: false },
      classifyList: [],
      uploadData: {},
      daoruType: null,
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length > 0;
    },
  },
  created() {
    let info = JSON.parse(localStorage.getItem("info"));
    this.companyId = info.companyId;
    this.userId = info.userId;
    this.uploadData = {
      companyId: info.companyId,
      userId: info.userId,
    };
    this.inits();
    this.getTypeList();
    this.getClassList();
  },
  methods: {
    getClassList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
        companyId: this.companyId,
      };
      getProduceClass(data).then((res) => {
        this.classifyList = res.data.list;
      });
    },
    delSearchItem(item) {
      deleteProduce({ produceId: item.produceId }).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.inits();
        }
      });
    },
    editItem(item) {
      this.ruleForm = JSON.parse(JSON.stringify(item));

      if (this.ruleForm.classify) {
        this.ruleForm.classify = this.ruleForm.classify * 1;
      }
      this.dialogVisible = true;
      if (this.ruleForm.cover) {
        this.fileList = [
          {
            url: this.ruleForm.cover,
            name: "",
          },
        ];
      }
      if (this.ruleForm.produceSort && this.ruleForm.produceSort.length > 0) {
        this.ruleForm.produceSort = this.ruleForm.produceSort.map((el) => {
          return el.secId;
        });
      }
    },
    getTypeList() {
      let prams = {
        companyId: this.companyId,
      };
      getProduceSort(prams).then((res) => {
        let arr = res.data.list || [];
        // this.typeList=res.data.list;
        if (arr) {
          arr.forEach((el) => {
            el.label = el.sortName;
            el.value = el.sortId;
            if (el.children && el.children.length > 0) {
              el.children.forEach((jq) => {
                jq.label = jq.sortName;
                jq.value = jq.sortId;
              });
            }
          });
        }
        this.typeList = JSON.parse(JSON.stringify(arr));
        console.log(this.typeList, "分类数据就");
      });
    },
    inits() {
      let prams = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        companyId: this.companyId,
      };
      getProduce(prams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.inits();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.inits();
    },
    addSearchItem() {
      this.ruleForm = {};
      this.fileList = [];
      this.ruleForm.companyId = this.companyId;
      this.ruleForm.userId = this.userId;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.fileList = [];
      this.ruleForm.cover = "";
    },
    handProgress1(event, file, fileList) {
      // this.$message({
      //   message: "导入中",
      // });
      this.daoruType = true;
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList = fileList;
      this.ruleForm.cover = `https://cdn-oss.jquen.com/${response.data}`;
    },
    uploadAddProduct(response, file, fileList) {
      console.log(response, "上传状态");
      this.$message.closeAll();
      this.daoruType = false;
      if (response.code == 200) {
        this.$message({
          message: "导入成功",
          type: "success",
        });
        this.inits();
      } else {
        this.$message({
          message: "导入文件失败，请稍后再试！",
          type: "error",
        });
      }
    },
    subMit() {
      console.log(this.ruleForm, "添加产品");
      saveProduce(this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功！");
          this.inits();
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .disabled .el-upload--picture-card {
  display: none;
}

::v-deep .el-loading-spinner{
  font-size: 36px;
  .el-icon-loading,.el-loading-text{
    color: green !important;
  }
}
::v-deep .uploadExcel {
  .el-upload--text {
    width: auto;
    height: auto;
    border: none;
    line-height: normal;
    border-radius: 0;
  }
}
</style>