<template>
  <div class="">
    <el-card shadow="never">
      <el-button
        type="primary"
        @click="(dialogVisible = true), (ruleForm = {}), (isEdit = false)"
        >添加分类</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column type="index" align="center" label="序号" />
          <el-table-column prop="className" align="center" label="标题" />
          <el-table-column prop="" align="center" label="排序">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="数值越大，分类排序越靠前"
                placement="top-start"
              >
                <el-input
                  v-model="scope.row.rank"
                  placeholder="数值越大，分类排序越靠前"
                  @change="saveSort(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openLog(scope.row, 1)"
                >设置</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除标签？"
                @confirm="openLog(scope.row, 2)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input
            v-model="ruleForm.className"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="封面图">
          <el-upload
            :class="{
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :limit="1"
            :file-list="fileList"
            accept=".png,.jpg"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import commonTable from "@/components/common/commonTable";
import {
  getProduceClass,
  saveProduceClass,
  deleteProduceClass,
} from "@/api/searchMange";
export default {
  components: {
    commonTable,
  },
  props: ["classify"],
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      isEdit: false,
      classifyType: 99,
      fileList: [],
      companyId:'',
      userId:''
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length > 0;
    },
  },
  created() {
    let info = JSON.parse(localStorage.getItem("info"));
    this.companyId = info.companyId;
    this.userId = info.userId;
  },
  mounted() {
    this.getData();
  },
  methods: {
    async saveSort(row) {
      if (row && row.rank != "") {
        console.log(row);
        const result = await saveProduceClass(row);
        console.log("result:", result);
        this.$message({
          type: "info",
          message: "保存成功",
        });
        this.getData();
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.classCover = "";
      this.fileList = [];
    },
    // 封面
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.classCover = `https://cdn-oss.jquen.com/${res.data}`;
    },
    submit() {
      if (!this.ruleForm.rank || this.ruleForm.rank == "") {
        this.ruleForm.rank = 1;
      }
      // if (!this.ruleForm.classify||this.ruleForm.classify==99) {
      //   return this.$message.warning("请选择分类类型");
      // }
      if(!this.ruleForm.companyId){
        this.ruleForm.companyId=this.companyId
      }
      if (!this.ruleForm.className) {
        return this.$message.warning("请输入分类名称");
      }
      if (this.ruleForm.className) {
        console.log(this.ruleForm,'添加');
        saveProduceClass(this.ruleForm).then(res=>{
              if (res.code == 200) {
              this.$message.success("保存成功！");
              this.dialogVisible = false;
              this.getData();
            }
        })
      }
    },
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        companyId:this.companyId
      };
      getProduceClass(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    openLog(item, i) {
      if (i == 1) {
        this.ruleForm = JSON.parse(JSON.stringify(item));
        this.isEdit = true;
        this.dialogVisible = true;
      } else if (i == 2) {
        deleteProduceClass({ classId: item.classId }).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }
    },
  },
};
</script>
    
<style lang="scss" scoped>
::v-deep.upload-demo {
  .el-upload {
    width: auto;
    height: auto;
    border: none;
    border-radius: 2px;
  }
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>